<template>
  <div class="content">
    <p class="title">平台隐私政策协议</p>
    <p>【特别提示】</p>
    <p>
      本政策仅适用于【宜昌良联信息技术有限公司】（以下简称“良联”）提供的产品和服务及其延伸的功能（以下简称“良联”），包括平台、网站、H5、小程序向您提供的各项产品和服务。如我们提供的某款产品有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品的隐私政策将优先适用，该款产品隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。<br />
    </p>
    <p>
      请仔细阅读《隐私政策》（尤其是粗体内容）并确定了解我们对您个人信息的处理规则。阅读过程中，如您有任何疑问，可通过《隐私政策》中的联系方式咨询我们。
    </p>
    <p>引言<br /></p>
    <p>
      欢迎您使用良联服务！我们非常重视用户的隐私和个人信息保护。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《隐私政策》（“本隐私政策”）向您说明在您使用我们的产品与/或服务时我们如何收集、使用、保存、共享和转移这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。<br />
    </p>
    <p>
      本隐私政策与您所使用的良联服务以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。<br />
    </p>
    <p>一、我们如何收集和使用您的个人信息<br /></p>
    <p>
      个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。<br />
    </p>
    <p>
      我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用产品与/或服务过程中主动提供或因使用产品与/或服务而产生的个人信息，以及从第三方获取您的相关个人信息。如果我们要将您的个人信息用于本政策未载明的其他用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。<br />
    </p>
    <p>（一）您须授权我们收集和使用您个人信息的情形<br /></p>
    <p>
      我们的产品与/或服务包括一些核心功能，这些功能包含了税务代理服务的功能及保障交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：<br />
    </p>
    <p>1、税务代理服务所必须的功能</p>
    <p></p>
    <p>（1）账号注册、登录与认证</p>
    <p></p>
    <p>
      当您使用平台线上税务代理服务系统时，你需要注册平台账号。当您注册平台账号时，您至少需要向我们提供您准备使用的平台账号名称、密码、手机号码，我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效。您的账号名为您的默认昵称，您可以修改和补充您的昵称、性别、生日以及您的实名认证相关信息，这些信息均属于您的“账号信息”。您补充的账号信息将有助于我们为您提供个性化的商品推荐和更优的购物体验，但如果您不提供这些补充信息，将会影响您使用税务代理服务的基本功能。<br />
    </p>
    <p>
      当您成功注册平台账号后，可以使用平台账号登录“平台”，登录时您需要向我们提供您的平台账号信息。若您不提供这类信息，您将无法登录平台，此时您为开展税务代理服务使用浏览和搜索功能，我们将不会收集您的上述个人信息。<br />
    </p>
    <p>
      您授权平台将您的个人信息发送给您指定的服务商。服务商再通过平台将您的服务外包发送给服务需求方。
    </p>
    <p>（2）下单及订单管理 <br /></p>
    <p>
      当您指定的服务商与需求方达成一致意向时，您应根据订单要求向需求方提供服务。您需要在订单中至少填写您的姓名、邮寄地址或电子邮箱以及手机号码，同时该订单中会载明订单号、下单时间、服务内容、服务费。<br />
    </p>
    <p>（3）交付产品、支付功能</p>
    <p></p>
    <p>
      因您将所提供的服务转包给服务商，服务商将您的服务提供给需求方后，需求方应向服务商支付服务费，服务商再将所收取的服务费通过银行转账汇至您的银行账户。<br />
    </p>
    <p>（4）客服与售后功能</p>
    <p></p>
    <p>
      我们的电话客服和售后功能会使用您的账号信息和订单信息。为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更服务地址、联系人或联系电话。<br />
    </p>
    <p>2、保障交易安全所必须的功能<br /></p>
    <p>
      为保障您使用我们的产品与/或服务时系统的稳定性和安全性，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们需要收集您的设备信息（设备型号、设备Mac地址、应用进程列表、设备识别码、设备序列号、操作系统及软件版本、设备状态、网络状况）、历史上网记录、日志信息、面部信息、IP地址、位置信息、浏览信息、订单信息、wifi信息、IMSI、SIM卡信息、电池使用情况来识别是否为真实自然人用户、是否为异常地点登录、判断您的账号风险，并可能会记录一些我们认为有风险的链接（“URL”）；我们也会收集您的设备信息用于对良联系统问题进行分析、统计流量并排查可能存在的风险，在您选择向我们发送异常信息时予以排查。如果您不提供上述信息，我们则无法在您使用良联服务过程中对您的服务及账号安全进行保护。
    </p>
    <p></p>
    <p>（二）您个人信息使用的规则</p>
    <p></p>
    <p>
      1、我们会根据本隐私政策的内容，为实现我们的产品与/或服务功能对所收集的个人信息进行使用。
    </p>
    <p></p>
    <p></p>
    <p>2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理。</p>
    <p></p>
    <p></p>
    <p>
      3、当我们展示您的个人信息时，我们会采用包括去标识化或者匿名化处理方式对您的信息进行脱敏，以保护您的信息安全。
    </p>
    <p></p>
    <p></p>
    <p>
      4、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的明示同意。
    </p>
    <p></p>
    <p></p>
    <p>
      请您理解，我们向您提供的产品与/或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务。
    </p>
    <p></p>
    <p>二、我们如何对外提供您的个人信息</p>
    <p>（一）共享</p>
    <p></p>
    <p>
      1、我们不会与良联以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
    </p>
    <p></p>
    <p>（1）事先获得您明确的同意或授权；</p>
    <p>（2）根据法律法规、行政及司法部门强制性要求进行提供；</p>
    <p></p>
    <p>
      （3）在法律法规允许的范围内，为维护良联或合作伙伴、您或其他良联用户或社会公众利益、财产或安全免遭损害而有必要提供；
    </p>
    <p></p>
    <p></p>
    <p>
      （4）只有共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
    </p>
    <p></p>
    <p>（5）应您需求为您处理您与他人的纠纷或争议；</p>
    <p></p>
    <p>
      （6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
    </p>
    <p></p>
    <p>（7）基于学术研究而使用；</p>
    <p>（8）基于符合法律法规的社会公共利益、突发公共卫生事件而使用。</p>
    <p></p>
    <p>
      2、为向您提供相关产品或服务，向您展示可能感兴趣的内容，保护您的账号与交易安全，我们可能会将您的个人信息与我们的合作伙伴共享，共享内容及目的。我们的关联方或者合作伙伴如要改变个人信息的处理目的，将再次征求您的授权同意。
    </p>
    <p></p>
    <p></p>
    <p>
      请您注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息。请您共享时谨慎考虑并决定。
    </p>
    <p></p>
    <p>（二）委托处理</p>
    <p></p>
    <p>
      我们可能委托授权合作伙伴处理您的个人信息，以便向您提供相应的产品或服务。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供产品或服务所必要的个人信息。如果我们的合作伙伴将您的个人信息用于我们未委托的用途，其将单独征得您的同意。
    </p>
    <p></p>
    <p>我们的合作伙伴包括以下类型：</p>
    <p></p>
    <p>
      （1）提供技术服务的供应商。我们可能会将您的个人信息提供给支持我们功能的第三方，包括为我们提供基础设施技术服务、数据分析服务及数据处理服务等的供应商。
    </p>
    <p></p>
    <p></p>
    <p>
      （2）广告、分析服务类合作伙伴。我们会遵守法律法规及行业通用安全技术，委托这些合作伙伴处理与广告投放、覆盖面和有效性相关的信息。
    </p>
    <p></p>
    <p></p>
    <p>
      对我们委托合作伙伴处理个人信息的，我们会与其签署数据保护协议，要求其按照协议约定、本隐私政策以及相关法律法规要求来处理个人信息。
    </p>
    <p></p>
    <p>（三）转移</p>
    <p></p>
    <p>
      在因合并、分立、解散、被宣告破产等原因需要转移个人信息的，我们会向您告知接收方的名称或者姓名和联系方式，要求接收您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。
    </p>
    <p></p>
    <p>（四）公开披露</p>
    <p></p>
    <p>
      我们仅会在以下情况，且在采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：
    </p>
    <p></p>
    <p>（1）根据您单独同意的授权范围披露相应个人信息；</p>
    <p></p>
    <p>
      （2）如果您严重违反法律法规或者相关协议、规则，我们可能会披露您的相关违规行为及我们对您采取的措施。
    </p>
    <p></p>
    <p>三、我们如何保护和保存您的个人信息</p>
    <p>（一）我们保护您个人信息的技术与措施</p>
    <p></p>
    <p>
      我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：
    </p>
    <p></p>
    <p>1、数据安全技术措施</p>
    <p></p>
    <p>
      我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改，避免数据的损坏或丢失。
    </p>
    <p></p>
    <p>2、为保护个人信息采取的其他安全措施</p>
    <p></p>
    <p>
      通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用；通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制；建立数据安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。
    </p>
    <p></p>
    <p></p>
    <p>
      互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。在通过良联与第三方进行网上产品与/或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
    </p>
    <p></p>
    <p>3、安全事件处置</p>
    <p></p>
    <p>
      为应对个人信息泄露、损毁和丢失等可能出现的风险，我们制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。我们也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。
    </p>
    <p></p>
    <p></p>
    <p>
      一旦发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。同时，我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
    </p>
    <p></p>
    <p></p>
    <p>
      如果您对我们的个人信息保护有任何疑问，可通过本隐私政策中约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账号及密码发生泄露，请您立即通过本隐私政策“七、如何联系我们”中明确的联系方式联络我们，以便我们采取相应措施。
    </p>
    <p></p>
    <p>（二）您个人信息的保存</p>
    <p>1、您的个人信息将存储于中华人民共和国境内。</p>
    <p></p>
    <p>
      2、在您使用我们的产品与/或服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存，同时将结合法律有强制的留存要求期限的规定确定，如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。
    </p>
    <p></p>
    <p></p>
    <p>
      3、请您注意，当您成功注销平台账号后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。
    </p>
    <p></p>
    <p></p>
    <p>
      4、如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。
    </p>
    <p></p>
    <p>四、您如何管理您的个人信息</p>
    <p></p>
    <p>
      良联非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：
    </p>
    <p></p>
    <p>1、访问和更正您的个人信息</p>
    <p></p>
    <p>
      除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括（1）您的账号信息；（2）您的收件信息；（3）您的订单信息（4）您的浏览信息；（5）您的评论信息；（6）您的发票信息；（7）您的档案信息。
    </p>
    <p></p>
    <p></p>
    <p>
      对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。
    </p>
    <p></p>
    <p>2、删除您的个人信息</p>
    <p></p>
    <p>
      您在我们的产品与/或服务页面中可以直接清除或删除的信息，包括订单信息、浏览信息、收货地址信息。
    </p>
    <p></p>
    <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
    <p>（1）如果我们处理个人信息的行为违反法律法规；</p>
    <p>（2）如果我们收集、使用您的个人信息，却未征得您的同意；</p>
    <p>（3）如果我们处理个人信息的行为严重违反了与您的约定；</p>
    <p>（4）如果您不再使用我们的产品或服务，或您注销了平台账号；</p>
    <p>（5）如果我们终止服务及运营，或者保存期限已届满。</p>
    <p></p>
    <p>
      若我们决定响应您的删除请求，我们还将同时尽可能通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体已获得您的独立授权。
    </p>
    <p></p>
    <p></p>
    <p>
      请您注意，当您成功注销平台账号后，我们将对您的个人信息进行删除或匿名化处理。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到可以删除或实现匿名化。
    </p>
    <p></p>
    <p>3、改变您授权同意的范围或撤回您的授权</p>
    <p></p>
    <p>
      您可以通过删除信息、关闭设备功能、在良联网站或软件中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账号的方式，撤回我们继续收集您个人信息的全部授权。
    </p>
    <p></p>
    <p></p>
    <p>
      请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
    </p>
    <p></p>
    <p>4、注销账号</p>
    <p></p>
    <p>
      您可以在我们的产品中直接申请注销账号。您注销账号后，我们将停止为您提供产品与/或服务，并根据适用法律的要求删除您的个人信息，或进行匿名化处理。
    </p>
    <p></p>
    <p>5、获取您的个人信息副本</p>
    <p></p>
    <p>
      您有权获取您的个人信息副本。如您需要获取我们收集的您的个人信息副本，您随时联系我们。在符合相关法律规定且技术可行的前提下，我们将根据您的要求向您提供相应的个人信息副本。
    </p>
    <p></p>
    <p>6、响应您的请求</p>
    <p></p>
    <p>
      如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息或者获取个人信息副本，或您认为良联存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以发送电子邮件至
    </p>
    <p>&nbsp;service@zanhua.com.cn</p>
    <p>
      或通过本隐私政策中的其他方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或提供您的身份证明文件，我们将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
    </p>
    <p></p>
    <p>五、未成年人的个人信息保护</p>
    <p></p>
    <p>
      1、非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得您监护人的同意。良联根据国家相关法律法规的规定保护未成年人的个人信息。
    </p>
    <p></p>
    <p></p>
    <p>
      2、我们不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
    </p>
    <p></p>
    <p></p>
    <p>
      3、如果有事实证明未成年人在未取得监护人同意的情况下注册使用了我们的产品与/或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。
    </p>
    <p></p>
    <p>六、通知和修订</p>
    <p></p>
    <p>
      1、为给您提供更好的服务以及随着良联业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在网站、移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新。
    </p>
    <p></p>
    <p></p>
    <p>
      2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。
    </p>
    <p></p>
    <p>本隐私政策所指的重大变更包括但不限于：</p>
    <p></p>
    <p>
      （1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
    </p>
    <p></p>
    <p></p>
    <p>
      （2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
    </p>
    <p></p>
    <p>（3）个人信息共享、转移或公开披露的主要对象发生变化；</p>
    <p>（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
    <p></p>
    <p>
      （5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </p>
    <p></p>
    <p>（6）个人信息安全影响评估报告表明存在高风险时。</p>
    <p></p>
    <p>
      3为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用我们的服务，即代表您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。我们鼓励您在每次使用我们服务时都查阅本政策。
    </p>
    <p></p>
    <p>七、如何联系我们</p>
    <p></p>
    <p>
      1、如您对本隐私政策或您个人信息的相关事宜或者投诉、举报、有任何问题、意见或建议，请与我们联系。
    </p>
    <p></p>
    <p></p>
    <p>
      2、一般情况下，我们将在15天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。
    </p>
    <p></p>
    <p></p>
    <p>
      附：个人信息及敏感个人信息示例个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。（本定义出自于《中华人民共和国个人信息保护法》）。
    </p>
    <p></p>
  </div>
</template>

<script>
export default { name: 'PrivacyLianglian' };
</script>
