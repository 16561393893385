<template>
  <div class="Additional">
    <van-form @submit="handleSubmit">
      <div class="title">附加采集</div>

      <van-divider class="divider" />

      <div class="result-wrapper">
        <div v-for="(item, index) in detail" :key="index">
          <van-field
            v-if="item.subType == 'input'"
            v-model="item.subValue"
            :label="item.subName"
            :placeholder="item.subTip || '请输入'"
            :rules="[{ required: item.required ? true : false, message: '' }]"
          />

          <van-field
            v-if="item.subType == 'select'"
            readonly
            clickable
            v-model="item.subValue"
            :label="item.subName"
            :placeholder="item.subTip || '请选择'"
            :rules="[{ required: item.required ? true : false, message: '' }]"
            @click="showPicker(item.subSelect, index)"
          />

          <van-field
            v-if="item.subType == 'date'"
            readonly
            clickable
            v-model="item.subValue"
            :label="item.subName"
            :placeholder="item.subTip || '请选择'"
            :rules="[{ required: item.required ? true : false, message: '' }]"
            :min-date="new Date('2020/01/01')"
            @click="showDatetime(index)"
          />

          <van-field
            v-if="item.subType == 'file'"
            :label="item.subName"
            :rules="[
              { required: item.required ? true : false, message: '请上传' },
            ]"
          >
            <template #input>
              <van-uploader
                :after-read="(file) => afterRead(file, item.id, index)"
                :max-count="1"
                @delete="(file, current) => handleDelete(file, current, index)"
                v-model="item.subValue"
              >
              </van-uploader>
            </template>
          </van-field>
        </div>
        <!-- 
        <van-field
          label="银行卡"
          :rules="[{ required: true, message: '请上传' }]"
        >
          <template #input>
            <van-uploader
              ref="bankImg"
              :after-read="(file) => afterRead(file, 'bankImg')"
              :max-count="1"
              @delete="
                (file, current) => handleDelete(file, current, 'bankImg')
              "
              v-model="form.bankImg"
            >
            </van-uploader>
          </template>
        </van-field> -->
        <button
          v-if="!isSubmiting"
          class="btn"
          :style="{ background: '#' + $store.state.color }"
        >
          确认提交
        </button>
        <button v-else class="btn" disabled>提交中</button>
      </div>
    </van-form>

    <van-popup v-model="selectVisible" position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @confirm="selectConfirm"
        @cancel="selectVisible = false"
      />
    </van-popup>

    <van-popup v-model="datetimeVisible" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        @confirm="datetimeConfirm"
        @cancel="datetimeVisible = false"
      />
      <!-- :min-date="minDate" :max-date="maxDate" -->
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import {
  ActionSheet,
  Button,
  DatetimePicker,
  Dialog,
  Divider,
  Field,
  Form,
  Notify,
  Toast,
  Uploader,
} from 'vant';
import OSS from 'ali-oss';
import { getOssToken, getOssFile } from '../utils/getOss';
import Compressor from 'compressorjs';

Vue.use(ActionSheet)
  .use(Button)
  .use(DatetimePicker)
  .use(Dialog)
  .use(Divider)
  .use(Field)
  .use(Form)
  .use(Notify)
  .use(Uploader);

export default {
  name: 'Additional',
  components: {},
  data() {
    return {
      accountId: null,
      form: {},
      uploading: null,
      isSubmiting: false,
      clientOSS: {},
      randomString: '',
      detail: [],
      columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
      selectVisible: false,
      detailIndex: null,
      minDate: new Date(1970, 0, 1),
      maxDate: new Date(2070, 11, 31),
      currentDate: new Date(),
      datetimeVisible: false,
      fileList: [],
    };
  },
  computed: {
    ...mapState({
      taskId: (state) => state.taskId,
      color: (state) => state.color,
    }),
  },
  created() {
    this.getToken();

    this.randomString = this.getRandomString(10);

    const { accountId } = this.$route.query;
    this.accountId = accountId;
    this.getDetail(accountId);
  },
  methods: {
    async getDetail(accountId) {
      const data = await this.$axios.post(
        '/tax/wechat/lableColl/getOtherCollConf',
        {
          positionId: this.taskId,
          accountId,
        }
      );
      if (data.success) {
        let arr = [...data.data];
        for (const item of arr) {
          if (item.subType == 'file') {
            item.subValue = [];
          }
        }
        this.detail = arr;

        this.fileList = Array.from({ length: arr.length }, function () {
          return '';
        });
      }
    },
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res.data);
        this.clientOSS = clientOSS;
      });
    },
    async afterRead(file, id, index) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `ling_gong/cert/${date}/${t}-${this.randomString}-${id}${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        this.fileList.splice(index, 1, path);
      }
      this.uploading.clear();
    },
    handleDelete(file, current, index) {
      this.fileList.splice(index, 1, '');
    },
    async handleSubmit() {
      this.isSubmiting = true;
      let postData = {
        positionId: this.taskId,
        accountId: this.accountId,
        subList: [],
      };
      for (const [index, item] of this.detail.entries()) {
        let obj = {
          subId: item.id,
          subValue:
            item.subType == 'file' ? this.fileList[index] : item.subValue,
        };
        postData.subList.push(obj);
      }
      console.log(postData);
      const data = await this.$axios({
        url: 'tax/wechat/lableColl/saveOtherColl',
        method: 'post',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        data: postData,
      });
      if (data.success) {
        this.getUrl(this.accountId);
        this.uploading = Toast.loading({
          message: '合同生成中',
          forbidClick: true,
        });
      } else {
        this.isSubmiting = false;
      }
    },
    async getUrl(accountId) {
      const data = await this.$axios.post('/tax/wechatController/doEleSign', {
        accountId,
        taskId: this.taskId,
      });
      if (data.success) {
        if (data.data.shortUrl) {
          window.location.href = data.data.shortUrl;
        } else {
          this.uploading.clear();
          this.$router.replace('result');
        }
      }
      this.isSubmiting = false;
    },
    handleCompressor(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          // quality: 0.3,
          maxWidth: 1000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    getRandomString(len) {
      len = len || 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        maxPos = chars.length,
        pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    showPicker(items, index) {
      this.columns = items.split(',');
      this.selectVisible = true;
      this.detailIndex = index;
    },
    selectConfirm(value) {
      this.selectVisible = false;
      this.detail[this.detailIndex].subValue = value;
    },
    showDatetime(index) {
      this.datetimeVisible = true;
      this.detailIndex = index;
    },
    datetimeConfirm() {
      this.datetimeVisible = false;
      this.detail[this.detailIndex].subValue = this.$dayjs(
        this.currentDate
      ).format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss">
@import '../styles/vars';

.Additional {
  min-height: 100vh;
  background: #fff;
  padding-bottom: 0.58rem;
  box-sizing: border-box;

  .title {
    padding: 0.16rem 0;
    // color: #999;
    font-size: 0.18rem;
    text-align: center;
  }

  .divider {
    margin: 0;
  }

  .result-wrapper {
    .btn {
      display: block;
      width: 3.8rem;
      height: 0.44rem;
      background-color: $blue;
      margin: 0.46rem auto 0;
      padding: 0;
      border: none;
      border-radius: 0.22rem;
      color: #fff;
      font-size: 0.17rem;
      // box-shadow: 0 0.03rem 0.1rem rgba($blue, 0.3);
      box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);

      @at-root .theme-orange & {
        background: $orange;
        border-color: $orange;
        // box-shadow: 0 0.03rem 0.1rem rgba($orange, 0.3);
      }

      &:disabled {
        background-color: $gray !important;
        // box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);
      }
    }
  }
}
</style>
