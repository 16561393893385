<template>
  <div class="Agreement">
    <template v-if="platformId == 2">
      <PrivacyDamai v-if="type == 'privacy'" />
      <RegisterDamai v-if="type == 'register'" />
    </template>
    <template v-else-if="platformId == 5">
      <PrivacyLianglian v-if="type == 'privacy'" />
      <RegisterLianglian v-if="type == 'register'" />
    </template>
    <template v-else-if="platformId == 6">
      <PrivacyZanyu v-if="type == 'privacy'" />
      <RegisterZanyu v-if="type == 'register'" />
    </template>
    <template v-else-if="platformId == 7">
      <PrivacyXinrenyun v-if="type == 'privacy'" />
      <RegisterXinrenyun v-if="type == 'register'" />
    </template>
    <template v-else>
      <Privacy v-if="type == 'privacy'" />
      <Register v-if="type == 'register'" />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Privacy from './Privacy.vue';
import Register from './Register.vue';
import PrivacyDamai from './PrivacyDamai.vue';
import RegisterDamai from './RegisterDamai.vue';
import PrivacyLianglian from './PrivacyLianglian.vue';
import RegisterLianglian from './RegisterLianglian.vue';
import PrivacyZanyu from './PrivacyZanyu.vue';
import RegisterZanyu from './RegisterZanyu.vue';
import PrivacyXinrenyun from './PrivacyXinrenyun.vue';
import RegisterXinrenyun from './RegisterXinrenyun.vue';

export default {
  name: 'Agreement',
  components: {
    Privacy,
    Register,
    PrivacyDamai,
    RegisterDamai,
    PrivacyLianglian,
    RegisterLianglian,
    PrivacyZanyu,
    RegisterZanyu,
    PrivacyXinrenyun,
    RegisterXinrenyun,
  },
  props: ['type', 'agreementVisible'],
  computed: {
    ...mapState({
      platformId: (state) => state.platformId,
    }),
  },
  watch: {
    agreementVisible: function (n, o) {
      n && (document.querySelector('#content').scrollTop = 0);
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import '../styles/vars';
.Agreement {
  .content {
    max-height: 3.2rem;
    margin: 0.2rem 0;
    padding: 0 0.2rem;
    font-size: 0.12rem;
    text-align: justify;
    overflow-y: auto;

    p {
      margin: 0.2rem 0;
      font-size: 0.14rem;
      &.title {
        margin: 0;
        padding: 0;
        color: $font;
        font-size: 0.16rem;
      }
    }
  }
}
</style>
