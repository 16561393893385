<template>
  <div class="Standard">
    <van-form @submit="handleSubmit">
      <div class="title">请拍摄并上传您的有效身份证</div>

      <div class="upload-wrapper">
        <div class="item item-front" @click="chooseFileFront">
          <van-uploader ref="cardFront" :after-read="afterRead1">
            <div class="inner">
              <img v-if="!image1" src="../assets/auth_front.png" alt="" />
              <img v-else class="uploaded-img" :src="image1" alt="" />
              <p :style="{ background: '#' + $store.state.color }">
                身份证人像面
              </p>
            </div>
          </van-uploader>
        </div>
        <div class="item item-back" @click="chooseFileBack">
          <van-uploader ref="cardBack" :after-read="afterRead2">
            <div class="inner">
              <img v-if="!image2" src="../assets/auth_back.png" alt="" />
              <img v-else class="uploaded-img" :src="image2" alt="" />
              <p :style="{ background: '#' + $store.state.color }">
                身份证国徽面
              </p>
            </div>
          </van-uploader>
        </div>
      </div>

      <van-divider />

      <div class="upload-tips">
        <p><b>拍摄身份证要求：</b></p>
        <p>大陆公民持有的本人有效二代身份证；</p>
        <p>拍摄时确保身份证<span>边框完整，字体清晰，亮度均匀；</span></p>
        <ul>
          <li>
            <img src="../assets/auth_tips_01.png" alt="" />
            <span>标准</span>
          </li>
          <li>
            <img src="../assets/auth_tips_02.png" alt="" />
            <span>边框缺失</span>
          </li>
          <li>
            <img src="../assets/auth_tips_03.png" alt="" />
            <span>边框模糊</span>
          </li>
          <li>
            <img src="../assets/auth_tips_04.png" alt="" />
            <span>闪光强烈</span>
          </li>
        </ul>
      </div>

      <van-divider class="divider" />

      <div class="result-wrapper">
        <van-field
          v-model="form.name"
          label="姓名"
          placeholder="请输入姓名"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="form.idNo"
          label="身份证号"
          placeholder="请输入身份证号"
          readonly
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-if="config.collBank"
          label="银行卡"
          :rules="[{ required: true, message: '请上传' }]"
        >
          <template #input>
            <van-uploader
              ref="bankImg"
              :after-read="(file) => afterRead(file, 'bankImg')"
              :max-count="1"
              @delete="
                (file, current) => handleDelete(file, current, 'bankImg')
              "
              v-model="aForm.bankImg"
            >
            </van-uploader>
          </template>
        </van-field>
        <van-field
          v-model="form.bankCode"
          label="银行卡号"
          placeholder="请输入银行卡号"
          @blur="getBankName"
          :disabled="bankCodeDisabled"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="form.bank"
          label="所属银行"
          placeholder="请输入所属银行"
          :rules="[{ required: true, message: '' }]"
        />
        <div v-if="config.collHealth">
          <van-field
            label="健康证"
            :rules="[{ required: true, message: '请上传' }]"
          >
            <template #input>
              <van-uploader
                ref="healthImg"
                :after-read="(file) => afterRead(file, 'healthImg')"
                :max-count="1"
                @delete="
                  (file, current) => handleDelete(file, current, 'healthImg')
                "
                v-model="aForm.healthImg"
              >
              </van-uploader>
            </template>
          </van-field>
          <van-field
            readonly
            clickable
            name="datetimePicker"
            v-model="aForm.healthDate"
            label="健康证到期日"
            placeholder="请输入健康证到期日"
            :rules="[{ required: true, message: '' }]"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-datetime-picker
              type="date"
              :min-date="minDate"
              :max-date="maxDate"
              @confirm="onConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
          <van-field
            v-model="aForm.healthDep"
            label="健康证发证机关"
            placeholder="请输入健康证发证机关"
            :rules="[{ required: true, message: '' }]"
          />
          <van-field
            v-model="aForm.healthNo"
            label="健康证号"
            placeholder="请输入健康证号"
            :rules="[{ required: true, message: '' }]"
          />
        </div>
        <van-field
          v-model="form.phone"
          label="手机号码"
          placeholder="请输入手机号码"
          :rules="[{ required: true, message: '' }]"
        />
        <van-field
          v-model="form.smsCode"
          center
          clearable
          label="短信验证码"
          maxlength="4"
          placeholder="请输入短信验证码"
          :rules="[{ required: true, message: '' }]"
        >
          <template #button>
            <van-button
              v-if="!counting"
              class="btn-send"
              size="small"
              type="info"
              plain
              :disabled="form.phone.length < 11"
              @click="getCode"
              >获取验证码</van-button
            >
            <span v-if="counting" class="count-down"
              >{{ seconds }}s后可重发</span
            >
          </template>
        </van-field>
        <div class="tips">
          <span @click="showAgreement('privacy')">《平台隐私政策协议》</span>
          <span @click="showAgreement('register')">《平台注册服务协议》</span>
        </div>
        <!-- :disabled="isDisabled" -->
        <!-- @click="handleSubmit" -->
        <button
          v-if="!isSubmiting"
          class="btn"
          :style="{ background: '#' + $store.state.color }"
        >
          身份认证
        </button>
        <button v-else class="btn" disabled>提交中</button>
      </div>
    </van-form>

    <van-dialog
      v-model="agreementVisible"
      confirmButtonText="我已阅读并同意以上条款"
      width="3.6rem"
      @confirm="handleAgreementConfirm"
    >
      <Agreement :type="type" :agreementVisible="agreementVisible" />
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex';
import {
  ActionSheet,
  Button,
  DatetimePicker,
  Dialog,
  Divider,
  Field,
  Form,
  Notify,
  Toast,
  Uploader,
} from 'vant';
import Agreement from '../components/Agreement.vue';
import OSS from 'ali-oss';
import { getOssToken, getOssFile } from '../utils/getOss';
import Compressor from 'compressorjs';

Vue.use(ActionSheet)
  .use(Button)
  .use(DatetimePicker)
  .use(Dialog)
  .use(Divider)
  .use(Field)
  .use(Form)
  .use(Notify)
  .use(Uploader);

export default {
  name: 'Standard',
  components: {
    Agreement,
  },
  data() {
    return {
      signaType: null,
      image1: null,
      image2: null,
      successFlag1: false,
      successFlag2: false,
      form: {
        idCardFront: '',
        idCardReverse: '',
        name: '',
        idNo: '',
        bank: '',
        bankCode: '',
        phone: '',
        smsCode: '',
        smsCodeId: '',
        issuingAuthority: '',
        issuingDate: '',
        loseDate: '',
      },
      seconds: 60,
      counting: false,
      uploading: null,
      agreementVisible: true,
      // baseUrl: '',
      isSubmiting: false,
      clientOSS: {},
      randomString: '',
      type: 'privacy',
      config: {},
      tipMessageFlag: true,
      aForm: {
        bankImg: [],
        healthImg: [],
        healthDate: '',
        healthDep: '',
        healthNo: '',
      },
      bankImg: [],
      healthImg: [],
      bankCodeDisabled: false,
      value: '',
      showPicker: false,
      minDate: new Date(),
      maxDate: new Date(),
    };
  },
  computed: {
    isDisabled() {
      return (
        !this.form.name ||
        !this.form.idNo ||
        !this.form.bank ||
        !this.form.bankCode ||
        !this.form.phone ||
        !this.form.smsCode ||
        !this.image1 ||
        !this.image2 ||
        !this.successFlag1 ||
        !this.successFlag2
      );
    },
    ...mapState({
      taskId: (state) => state.taskId,
      color: (state) => state.color,
    }),
  },
  created() {
    let fromNew = this.$route.query.fromNew;
    fromNew && (this.agreementVisible = false);

    this.getToken();

    this.getSignaType();

    this.randomString = this.getRandomString(10);

    this.getConfig();

    let max = this.$dayjs(this.$dayjs().add(3, 'year'));
    this.minDate = new Date(this.$dayjs().get('year'), 0, 1);
    this.maxDate = new Date(max.get('year'), 11, 31);
  },
  methods: {
    onConfirm(time) {
      this.aForm.healthDate = this.$dayjs(time).format('YYYY-MM-DD');
      this.showPicker = false;
    },
    async getConfig() {
      const data = await this.$axios.post(
        '/tax/wechat/lableColl/queryCollConf',
        {
          positionId: this.taskId,
        }
      );
      if (data.success) {
        this.config = data.data;
      }
    },
    getToken() {
      getOssToken().then((res) => {
        const clientOSS = new OSS(res.data);
        this.clientOSS = clientOSS;
      });
    },
    async getSignaType() {
      const data = await this.$axios.post(
        '/tax/wechat/user/queryTaskSignaType',
        {
          taskId: this.taskId,
        }
      );
      if (data.success) {
        this.signaType = data.data;
      }
    },
    chooseFileFront() {
      this.$refs.cardFront.chooseFile();
    },
    chooseFileBack() {
      this.$refs.cardBack.chooseFile();
    },
    async afterRead1(file) {
      let image = file.file;
      // console.log('before', image.size);
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
        // console.log('after', image.size);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `/ling_gong/cert/${date}/${t}-${this.randomString}-01${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        console.log(res);
        let url = await getOssFile(path);
        this.image1 = url;
        this.form.idCardFront = `${path}`;
        this.handleOcr(url, 'front');
      } else {
        this.uploading.clear();
      }
    },
    async afterRead2(file) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `/ling_gong/cert/${date}/${t}-${this.randomString}-02${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        let url = await getOssFile(path);
        this.image2 = url;
        this.form.idCardReverse = `${path}`;
        // this.handleOcr(url, 'back');
        this.successFlag2 = true;
      } else {
        this.successFlag2 = false;
      }
      this.uploading.clear();
    },
    async handleOcr(url, side) {
      const data = await this.$axios.post('/tool/baidu/urlIdNoOcr', {
        url,
        side,
        flag: 1,
      });
      if (data.success) {
        if (side == 'front') {
          let { 姓名: name, 公民身份号码: idno } = data.data.words_result;
          name && (this.form.name = name.words);
          idno && (this.form.idNo = idno.words);
          this.successFlag1 = true;
        } else {
          let {
            签发机关: issuingAuthority,
            签发日期: issuingDate,
            失效日期: loseDate,
          } = data.data.words_result;
          // if (!issuingAuthority || !issuingDate || !loseDate) {
          //   this.successFlag2 = false;
          //   Toast({
          //     message: '请上传正确的身份证图片',
          //     position: 'bottom',
          //   });
          //   return;
          // }
          issuingAuthority &&
            (this.form.issuingAuthority = issuingAuthority.words);
          issuingDate && (this.form.issuingDate = issuingDate.words);
          loseDate &&
            (this.form.loseDate =
              loseDate == '长期--' ? '9999-12-30' : loseDate.words);
          this.successFlag2 = true;
        }
      } else {
        side == 'front'
          ? (this.successFlag1 = false)
          : (this.successFlag2 = false);
      }
      this.uploading.clear();
    },
    async afterRead(file, current) {
      let image = file.file;
      if (image.size > 3 * 1024 * 1024) {
        image = await this.handleCompressor(image);
      }
      this.uploading = Toast.loading({
        duration: 0,
        message: '照片上传中...',
        forbidClick: true,
      });
      let t = new Date().getTime(),
        date = this.$dayjs().format('YYYYMMDD'),
        suffix = image.name.substring(image.name.lastIndexOf('.')),
        path = `ling_gong/cert/${date}/${t}-${this.randomString}-${current}${suffix}`;
      const res = await this.clientOSS.put(path, image, {
        headers: {
          'Content-Disposition': 'attachment',
        },
      });
      if (res.res.status === 200) {
        this[current].push(path);
        const url = await getOssFile(path);
        console.log(res);
        if (current == 'bankImg') {
          this.handleOcrBank(url);
        }
      }
      this.uploading.clear();
    },
    async handleOcrBank(url) {
      const data = await this.$axios.post('/tool/baidu/urlBankOcr', {
        url,
      });
      if (data.success) {
        let { bank_card_number: bankCode, bank_name: bankName } =
          data.data.result;
        bankCode && (this.form.bankCode = bankCode);
        bankName && (this.form.bank = bankName);
        this.bankCodeDisabled = true;
      }
      this.uploading.clear();
    },
    handleDelete(file, current, type) {
      this[type].splice(current, 1);
    },
    async getCode() {
      let reg = /^1\d{10}$/;
      if (!reg.test(this.form.phone)) {
        Toast({
          message: '请输入正确格式的手机号码',
          position: 'bottom',
        });
        return;
      }
      this.counting = true;
      this.seconds = 60;
      let t1 = setInterval(() => {
        if (this.seconds < 1) {
          this.counting = false;
          clearInterval(t1);
        } else {
          this.seconds--;
        }
      }, 1000);
      const data = await this.$axios.post('/system/wechatController/sendMsg', {
        positionId: this.taskId,
        phone: this.form.phone,
        type: 3,
      });
      if (data.success) {
        this.form.smsCodeId = data.data;
      }
    },
    async handleSubmit() {
      if (!this.form.idCardFront || !this.form.idCardReverse) {
        Toast({
          message: '请上传身份证图片',
          position: 'bottom',
        });
        return;
      }
      this.isSubmiting = true;
      let postData = { ...this.form, positionId: this.taskId };
      const { collBank, collHealth } = this.config;
      collBank && (postData.bankImg = this.bankImg[0]);
      if (collHealth) {
        postData.healthImg = this.healthImg[0];
        postData.healthDate = this.aForm.healthDate;
        postData.healthDep = this.aForm.healthDep;
        postData.healthNo = this.aForm.healthNo;
      }
      console.log(postData);
      const data = await this.$axios.post(
        '/tax/wechat/user/addUserInfo',
        postData
      );
      if (data.success) {
        if (this.config.otherColl) {
          this.$router.push({
            name: 'additional',
            query: {
              taskId: this.taskId,
              accountId: data.data,
              color: this.color,
            },
          });
          return;
        }
        this.getUrl(data.data);
        this.uploading = Toast.loading({
          message: '合同生成中',
          forbidClick: true,
        });
      } else {
        this.isSubmiting = false;
      }
    },
    async getUrl(accountId) {
      const data = await this.$axios.post('/tax/wechatController/doEleSign', {
        accountId,
        taskId: this.taskId,
      });
      if (data.success) {
        if (data.data.shortUrl) {
          window.location.href = data.data.shortUrl;
        } else {
          this.uploading.clear();
          this.$router.replace('result');
        }
      }
      this.isSubmiting = false;
    },
    handleAgreementConfirm() {
      if (this.signaType) {
        this.$router.push({
          name: 'simple',
          query: { taskId: this.taskId, color: this.color },
        });
        this.agreementVisible = false;
      }
      if (this.tipMessageFlag && this.config.tipFlag) {
        Dialog.alert({
          message: this.config.tipMessage,
        }).then(() => {
          this.tipMessageFlag = false;
        });
      }
    },
    getBankName() {
      if (!this.form.bankCode) {
        return;
      }
      this.$axios
        .post('/system/systemFileController/getBankName', {
          cardNo: this.form.bankCode.trim(),
        })
        .then((data) => {
          if (data.success) {
            this.form.bank = data.data ? data.data.split('·')[0] : '';
          }
        })
        .catch((error) => {
          console.log(error);
          this.form.bank = '';
        });
    },
    handleCompressor(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          // quality: 0.3,
          maxWidth: 1000,
          success(result) {
            resolve(result);
          },
          error(err) {
            reject(err);
          },
        });
      });
    },
    getRandomString(len) {
      len = len || 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        maxPos = chars.length,
        pwd = '';
      for (let i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    showAgreement(type) {
      this.type = type;
      this.agreementVisible = true;
    },
  },
};
</script>

<style lang="scss">
@import '../styles/vars';

.Standard {
  min-height: 100vh;
  background: #fff;
  padding-bottom: 0.58rem;
  box-sizing: border-box;

  .title {
    padding: 0.1rem 0 0.16rem;
    color: #999;
    font-size: 0.13rem;
    text-align: center;
  }

  .upload-wrapper {
    padding: 0 0.2rem;
    text-align: center;

    .item {
      position: relative;
      z-index: 10;
      display: inline-block;
      width: 1.8rem;
      height: 1.62rem;
      background: #f4f8fe;
      border-radius: 0.1rem;
      vertical-align: top;

      .van-uploader {
        z-index: -10;
        pointer-events: none;
      }

      &.item-front {
        margin-right: 0.07rem;
      }

      &.item-back {
        margin-left: 0.07rem;
      }

      .inner {
        img {
          display: inline-block;
          width: 1.5rem;
          height: 1rem;
          margin: 0.15rem;
          vertical-align: top;
        }

        p {
          height: 0.32rem;
          line-height: 0.32rem;
          background: $blue;
          border-radius: 0 0 0.1rem 0.1rem;
          color: #fff;
          text-align: center;

          @at-root .theme-orange & {
            background: $orange;
          }
        }
      }
    }
  }

  .upload-tips {
    margin: 0.2rem;

    p {
      b {
        display: block;
        margin-bottom: 0.1rem;
      }

      span {
        color: #ff5050;
      }
    }

    ul {
      display: flex;
      margin-top: 0.24rem;

      li {
        flex: 1;
        text-align: center;

        &:not(:last-child) {
          padding-right: 0.05rem;
        }

        img {
          width: 100%;
        }

        span {
          color: #919191;
          font-size: 0.12rem;
        }
      }
    }
  }

  .divider {
    margin: 0;
  }

  .result-wrapper {
    .btn-send {
      @at-root .theme-orange & {
        color: $orange;
        border-color: $orange;
      }

      &:disabled {
        color: #e1e4ea;
        border-color: #e1e4ea;
        opacity: 1;
      }
    }

    .btn {
      display: block;
      width: 3.8rem;
      height: 0.44rem;
      background-color: $blue;
      margin: 0.46rem auto 0;
      padding: 0;
      border: none;
      border-radius: 0.22rem;
      color: #fff;
      font-size: 0.17rem;
      // box-shadow: 0 0.03rem 0.1rem rgba($blue, 0.3);
      box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);

      @at-root .theme-orange & {
        background: $orange;
        border-color: $orange;
        // box-shadow: 0 0.03rem 0.1rem rgba($orange, 0.3);
      }

      &:disabled {
        background-color: $gray !important;
        // box-shadow: 0 0.03rem 0.1rem rgba($gray, 1);
      }
    }
  }

  .tips {
    margin: 0.2rem 0;
    text-align: center;

    span {
      color: $blue;

      @at-root .theme-orange & {
        color: $orange;
      }
    }
  }
}
</style>
